import React, { useEffect, useState } from 'react'
import './style.css'
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container } from 'reactstrap';
import Form from 'antd/lib/form/Form';
import SearchForm from './SearchForm';
import DocumentActivity from './DocumentActivity';
import { Tabs } from 'antd/lib';
import UserActivity from './UserActivity';
import dayjs from 'dayjs';

const ActivityReports = () => {
  const [advanceForm] = Form.useForm();
  const [lo_formData, setFormData] = useState({})
  const [lb_isSearch, setIsSearch] = useState(false);
  const [lb_loading, setLoading] = useState(false);

  const handleSearchData = (formValue) => {
    console.log('---formValue', formValue);
    setFormData(formValue);
    setIsSearch(true)
  }

  useEffect(() => {
    const lo_inputData = {};
    lo_inputData['type'] = "";
    lo_inputData['document_type'] = "";
    lo_inputData['fromdate'] = dayjs().startOf('month');
    lo_inputData['todate'] = dayjs();
    setFormData(lo_inputData);
    advanceForm.setFieldsValue(lo_inputData)
  }, [])

  const lo_formProps = { lo_formData, lb_loading, setLoading, lb_isSearch };

  return (
    <>
      <Breadcrumb parent="Report" title="Activity Report" />
      <Container fluid={true}>
        <SearchForm advanceForm={advanceForm} handleSearchData={handleSearchData} lb_loading={lb_loading} />
        <Tabs
          defaultActiveKey="User-Activity"
          type="card"
          size={'middle'}
          items={[{
            label: `User Summary`,
            key: 'User-Activity',
            children: <UserActivity {...lo_formProps} />,
          },
          {
            label: `Activity Details`,
            key: 'Document-Activity',
            children: <DocumentActivity {...lo_formProps} />,
          },
          ]}
        />
        
      </Container>
    </>
  )
}

export default ActivityReports