import React, { useEffect, useState } from 'react'
import "./style.css"
import { getRequestApiByToken, postRequestApiByToken } from '../../../api';
import { Descriptions, Divider, Modal, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import { FilePath } from '../../../constant/globalConfig';
import { Link } from 'react-router-dom';
import { LikeOutlined, DislikeOutlined, ShareAltOutlined, DownloadOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { handleDownloadFile } from '../../../utility';
import { Button, Col, Flex, message, Tag } from 'antd/lib';

const ViewProjectDetails = ({lb_showProjectDetails, setShowProjectDetails, ls_projectId, setProjectId}) => {
    const [lb_spining, setSpining] = useState(false)
    const [lo_projectDetails, setProjectDetails] = useState({})
    const getAndSetProjectDetails = async () => {
        setSpining(true)
        const lo_response = await getRequestApiByToken(`project/${ls_projectId}`);
        console.log('--lo_response--',lo_response);
        const lo_record = lo_response?.Project ? lo_response.Project : {};
        setProjectDetails(lo_record);
        setSpining(false)
    }

    useEffect(() => {
        if(ls_projectId) getAndSetProjectDetails()
    }, [ls_projectId])
    // const url = lo_projectDetails?.fileuploadname;
    // const parts = url.split('.');
    // const extension = parts[parts.length - 1];
    const downloadFile = (url) => {
        try {
          const filename = url?.split('/').pop();
          const aTag = document.createElement("a");
          aTag.href = url;
          aTag.setAttribute('download', filename);
          document.body.appendChild(aTag);
          aTag.click();
          aTag.remove();
        } catch (error) {
          console.error("Error occurred while downloading file:", error);
        }
      };

    const handleDownloadAction = async (lo_projectDetails, ls_downloadType) => {
        console.log('---lo_projectDetails---', lo_projectDetails);
        
        if (!lo_projectDetails?.id) return;
        let lo_formData = { id: lo_projectDetails.id, type: 'download', document_type: ls_downloadType };
        const res = await postRequestApiByToken('documentactivityaction', lo_formData)
        console.log('download ressss', lo_projectDetails.id, res);

        if (res?.status == "Success") {
            message.success(res?.message);

            if (ls_downloadType = 'pds') {
                handleDownloadFile(lo_projectDetails?.fileuploadname_full_url, `pds-${lo_projectDetails?.mandate_no?.replace(",", "-")}`);
            } else if (ls_downloadType = 'tor') {
                handleDownloadFile(lo_projectDetails?.fileuploadname_tor_full_url, `tor-${lo_projectDetails?.mandate_no?.replace(",", "-")}`);
            }
        }
    }
      
  return (
    <>
        <Modal
            footer={false}
            // title={lo_projectDetails?.project_name}
            title={'Project Details'}
            open={lb_showProjectDetails}
            // onOk={onCancel}
            onCancel={() => {setShowProjectDetails(false); setProjectId('')}}
            // confirmLoading={lb_loading}
            // className='modal'
            width={700}
        >
            <div>
                <Spin spinning={lb_spining}>
                    <Divider/>
                    <Row>
                        <div className='prjct-detail-prnt'>
                        {/* <Descriptions bordered column={1} size="small" labelStyle={{minWidth:180}}> */}
                        <Descriptions bordered size="small" labelStyle={{minWidth:180}}>
                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Project Name</Typography.Text>}>{lo_projectDetails.project_name}</Descriptions.Item>

                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Project Code</Typography.Text>}>{lo_projectDetails.mandate_no}</Descriptions.Item>

                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Sectors</Typography.Text>}>{lo_projectDetails?.sector?.map((sectr) => sectr?.sector_name+', ')}</Descriptions.Item>

                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Practices</Typography.Text>}>{lo_projectDetails?.practice?.map((practic) => practic?.practice_name+', ')}</Descriptions.Item>

                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Country Name</Typography.Text>}>{lo_projectDetails?.country?.map((countr) => countr?.country_name+', ')}</Descriptions.Item>

                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Donor Name</Typography.Text>}>{lo_projectDetails?.donor?.map((rwoValue) => rwoValue?.client_name+', ')}</Descriptions.Item>

                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Client Name</Typography.Text>}>{lo_projectDetails?.contracted_by}</Descriptions.Item>

                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Implementing Agency</Typography.Text>}>{lo_projectDetails?.prj_for}</Descriptions.Item>

                            <Descriptions.Item span={2} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Start Date</Typography.Text>}><p style={{minWidth:"80px"}}>{lo_projectDetails?.start_date && moment(lo_projectDetails?.start_date).format('DD MMM YYYY')}</p></Descriptions.Item>

                            <Descriptions.Item span={2} labelStyle={{width:8}} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;End Date</Typography.Text>}><p style={{minWidth:"80px"}}>{lo_projectDetails?.end_date && moment(lo_projectDetails?.end_date).format('DD MMM YYYY')}</p></Descriptions.Item>

                                  <Descriptions.Item span={3} labelStyle={{ width: 8 }} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Consultancy Fees</Typography.Text>}>
                                      <Flex gap="4px 0" wrap>
                                        {lo_projectDetails?.total_values?.length > 0 ? 
                                            lo_projectDetails.total_values.reduce((acc, curr) => [...acc, <Tag bordered={false} key={`${curr?.currency?.currency}-${curr.total_value}`}>{curr?.currency?.currency} {curr.total_value}</Tag>], []) : 
                                            <></>
                                        }
                                      </Flex>
                                  </Descriptions.Item>

                                  <Descriptions.Item span={3} labelStyle={{ width: 8 }} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Value Of Services</Typography.Text>}>
                                      <Flex gap="4px 0" wrap>
                                          {lo_projectDetails?.firm_values?.length > 0 ?
                                              lo_projectDetails.firm_values.reduce((acc, curr) => [...acc, <Tag bordered={false} key={`${curr?.currency?.currency}-${curr.firm_value}`}>{curr?.currency?.currency} {curr.firm_value}</Tag>], []) :
                                              <></>
                                          }
                                      </Flex>
                                  </Descriptions.Item>

                            <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Consortium</Typography.Text>}>{(lo_projectDetails?.in_consortium == 1) ? `Yes, ${(lo_projectDetails?.consortium?.name) ? lo_projectDetails?.consortium?.name : ''}` : 'No'}</Descriptions.Item>
                            
                            
                            {(lo_projectDetails?.fileuploadname || lo_projectDetails?.fileuploadname_tor) ?
                            <Descriptions.Item label="" labelStyle={{display:'none'}}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:"space-evenly" }}>

                                    {lo_projectDetails?.fileuploadname ?
                                        <Button type="primary" icon={<DownloadOutlined style={{color:"green"}} />} onClick={() => handleDownloadAction(lo_projectDetails, 'pds')}>Download PDS</Button>
                                    : ''}

                                    {lo_projectDetails?.fileuploadname_tor ?
                                        <Button type="primary" icon={<DownloadOutlined style={{color:"green"}} />} style={{marginRight:-150}} onClick={() => handleDownloadAction(lo_projectDetails, 'tor')} >Download TOR</Button>
                                    : ''}
                                </div>
                            </Descriptions.Item>
                            : <></>}

                           
                        </Descriptions>
                        <Descriptions bordered size="small" labelStyle={{minWidth:180}}>
                        <Descriptions.Item span={3} label={<Typography.Text type="secondary" strong><i className="fa fa-circle"></i> &nbsp;Description</Typography.Text>}>{lo_projectDetails?.description}</Descriptions.Item>
                        </Descriptions>
                        </div>
                    </Row>
                </Spin>
            </div>

        </Modal>
    </>
  )
}

export default ViewProjectDetails