import React, { useEffect, useState, useRef } from 'react';
import { Badge, List, Space, Tag ,Row ,Col, Form, Popover, Typography, Descriptions, Tooltip, message, } from 'antd';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCountryCode, getFileExtension, getFileIconsObj } from '../../../../utility';
import { FileIconsObj } from '../../../../constant/globalConfig';
import Search from 'antd/lib/input/Search';
// import ShareModal from './ShareModal';
import { InfoCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { postRequestApiByToken } from '../../../../api';
import DeleteModal from './DeleteModal';
import { Modal } from 'antd/lib';
import TransferDocument from '../../TransferDocument';
import ShareModal from './ShareModal';

const startLoadTheMessage = () => message.loading({ content: 'Loading...', key: 'load', duration: 0 })
const stopLoadingMsh =(msg) => message.error({ content: msg, key: 'load', duration: 2, })
const stopLoadingSuccessMsh =(msg) => message.success({ content: msg, key: 'load', duration: 2, })

const DocumentListing = (lo_props) => {
    const { lb_showDocTypeTag=false, ls_headerText='Document Listing', la_documentTypes, lb_loading, handlePaginationChange, totalCount, la_documentList, getSearchData, setLoading, lb_showSearch=false, handleSearch, la_actionAceess=['view', 'delete', 'share', 'bookmark', 'edit'],handleTagPress,clickedTagIds} = lo_props;
    const ls_userType = useSelector(content => content.commonReducer.user_type);
    const popoverRef = useRef();
    const [lb_shareModal, setShareModal] = useState(false)
    const [ls_documentId, setDocumentId] = useState()
    const [modalVisible, setModalVisible] = useState(false)
    const [lb_buttonLoading, setButtonLoading] = useState(false);
    const [lb_transferModal, setTransferModal] = useState(false)

    const navigate = useNavigate();

    const handleBookedMarkButtonPress =async (item) => {
        setLoading(true);
        let lo_formData = {
          document_id: item?.id ,
          status: item.bookmarked ? 0 : 1 
        }
        const res = await postRequestApiByToken('addbookmark', lo_formData)
        if (res?.status =="Success") {
            message.success(res?.message);
            getSearchData()
        }
    }

  const handleTransferDocumentToProject = (ls_docId) => {
    console.log('ls_docId, ls_projectId',ls_docId);
    setTransferModal(true);
    setDocumentId(ls_docId);
    // Modal.confirm({
    //   title: 'Confirm',
    //   icon: <ExclamationCircleOutlined />,
    //   content: <>
    //     <Typography.Text>Do you want to Transfer ?</Typography.Text>
    //   </>,
    //   onOk() {
    //     if(ls_docId && ls_projectId) {
    //       transferDocumentToProject(ls_docId, ls_projectId)
    //     } else {
    //       message.error('Project not included!');
    //     }
    //   },
    //   //// cancelText: (setDelete(false)),
    //   // onCancel() {
    //   //     handleSetDataAction()
    //   // },
    // });
  }

    const getDocFileType = (row) => {
        const docdata = row?.document_file || (row?.otherdocuments?.length && row?.otherdocuments[0]?.document_file);
        const extnsn = getFileExtension(docdata);
        return <div>{(docdata && extnsn) ? getFileIconsObj(extnsn) : docdata}</div>
    }

    const getFileDetails = (row) => {
        const extension = getFileExtension(row?.document_file || '');
        const fileElement = (row?.document_file && extension) ? FileIconsObj[extension] : row?.document_file
        return { extension, fileElement }
    }
    const handleView =async (id) =>{
     
      let lo_formData = { id: id , type: 'view', document_type: 'other_document'};
      const res = await postRequestApiByToken('documentactivityaction', lo_formData)
      console.log('ressss',id, res);
      if (res?.status =="Success") {
        navigate('/other-documents/document-details', { state: { documentId: id } });
      }
    }
    const getDocsDetailsContent = (lo_documentData={}) => {
      console.log('lo_documentData---',lo_documentData);
        const items = [
          {
            key: '1',
            label: <Typography.Text type="secondary" strong><i className="fa fa-file-o docdtl-icn-color"></i> &nbsp;Document Name</Typography.Text>,
            children: lo_documentData?.title,
          },
          {
            key: '3',
            label: <Typography.Text type="secondary" strong><i className="fa fa-calendar-o docdtl-icn-color"></i> &nbsp;Created on</Typography.Text>,
            children: moment(lo_documentData?.created).format('DD MMM YYYY'),
          },
          {
            key: '2',
            label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Sectors</Typography.Text>,
            children: (lo_documentData?.sectors)?.map((rowvalue, inx) => `${inx > 0 ? ', ':''} ${rowvalue.sector_name}`),
          },

          {
            key: '2',
            label: <Typography.Text type="secondary" strong><i className="fa fa-book docdtl-icn-color"></i> &nbsp;Practices</Typography.Text>,
            children: (lo_documentData?.practices)?.map((rowvalue, inx) => `${inx > 0 ? ', ':''} ${rowvalue?.practice_name}`),
          },
        ];
        return (
        // <div className='col-xl-6 xl-100'>
            <Descriptions bordered column={1} size="small" labelStyle={{minWidth:180}}>
            {items.map((item) => (
                <Descriptions.Item key={item.key} label={item.label}>
                {item.children}
                </Descriptions.Item>
            ))}
            </Descriptions>
        // </div>
        )
    }

  const getActionForListing = (item, inx) => {
    const la_allActions = [
      <div className='first-li' >
        <div className='margin-first'>
          <i className="fa fa-file-o file-icon" style={{ color: "#4A1B97" }}></i> <Typography.Text type='secondary'>
            {item?.otherdocument_parent_category?.category_name || (item?.otherdocuments && item?.otherdocuments[0]?.otherdocument_parent_category?.category_name)}
            { }
          </Typography.Text>
        </div>
        <div className='margin-second'>
          <i className="fa fa-calendar" style={{ color: "#4A1B97" }}></i> <Typography.Text type='secondary'> Updated on: {moment(item?.modified_on || item?.otherdocuments[0]?.modified_on).format('DD MMM YY')}</Typography.Text>
        </div>
        {item?.shared_by && item?.shared_by[0] ? <> &nbsp;&nbsp;&nbsp;&nbsp;
          <div className='margin-second'>
            <i className="fa fa-user" style={{ color: "#4A1B97" }}></i> <Typography.Text type='secondary'>Shared by <strong>{item?.shared_by[0]?.name}</strong> on {moment(item?.shared_datetime).format('DD MMM YY')}</Typography.Text>
          </div>
        </> : <></>}

        {item?.share_with && item?.share_with[0] ? <> &nbsp;&nbsp;&nbsp;&nbsp;
          <div className='margin-second'>
            <i className="fa fa-user" style={{ color: "#4A1B97" }}></i> <Typography.Text type='secondary'>Shared with <strong>{item?.share_with[0]?.name}</strong> on {moment(item?.shared_datetime).format('DD MMM YY')}</Typography.Text>
          </div>
        </> : <></>}
      </div>,

      <div>{getDocFileType(item)}</div>,
    ]

    if (la_actionAceess.includes('view')) la_allActions.push(<Link
      // to={'/other-documents/document-details'}
      // state={{ documentId: (item?.document_id || item?.id) }}
      onClick={() => handleView(item?.document_id || item?.id)}
    ><i className="fa fa-eye text-secondary" style={{ fontSize: 18 }}></i></Link>);

    if (la_actionAceess.includes('bookmark')) la_allActions.push(<i className={item?.bookmarked ? 'icofont icofont-star' : 'icon-star'}
      onClick={() => handleBookedMarkButtonPress(item)}
      style={{ cursor: "pointer", fontSize: 18, color: item?.bookmarked ? '#f5cf00' : '#fff200' }}
    ></i>);

    if (la_actionAceess.includes('edit')) la_allActions.push(<Link to={'/other-documents/add-document'} state={{ updateId: item.id }}>
      <i className="fa fa-pencil" style={{ fontSize: 16, color: "rgb(40, 167, 69)" }} />
    </Link>);

    if (la_actionAceess.includes('transfer')) la_allActions.push(
      <i onClick={() => { handleTransferDocumentToProject(item?.id) }}
        className="fa fa-exchange text-secondary" style={{ fontSize: 18, cursor: "pointer" }}></i>);

    if (ls_userType == 'admin' && la_actionAceess.includes('delete')) la_allActions.push(
      <i onClick={() => { setModalVisible(true); setDocumentId(item.id) }}
        className="fa fa-trash-o" style={{ fontSize: 18, color: '#df0746', cursor: 'pointer' }} />);

    if (la_actionAceess.includes('share')) la_allActions.push(
      <i onClick={() => { setShareModal(true); setDocumentId(item.id) }}
        className="fa fa-share-alt text-secondary" style={{ fontSize: 18, cursor: "pointer" }}></i>);

    return la_allActions;
  }

  const handleDeleteButtonPress = async (row) => {
    setButtonLoading(true)
    const res = await postRequestApiByToken('deleteotherdocument/' + ls_documentId)
    if (res?.status == "Success") {
      message.success(res?.message);
      getSearchData()
      setModalVisible(false)
    }
    setButtonLoading(false)
  };

  const returnCountryIcon = (cntryNm) => {
    const cntryCode = getCountryCode(cntryNm);
    if (cntryCode) {
      return <Tooltip title={cntryNm}>
        <i className={`flag-icon flag-icon-${cntryCode.toLowerCase()}`} style={{ width: 45, fontSize: 16, padding: 5 }}></i>
      </Tooltip>;
    } else {
      return cntryNm;
    }
  }
    
  // const getCountryComponent = (item) => {
  //   if (item?.project?.country[0]?.country_name) {
  //     const ls_cntry = item?.project?.country[0]?.country_name;
  //     return returnCountryIcon(ls_cntry) || '-------';
  //   }
  //   else if (item?.otherdocuments && item?.otherdocuments[0]?.project?.country[0]?.country_name) {
  //     console.log('----------------item', item);
  //     const ls_cntry = item?.otherdocuments[0]?.project?.country[0]?.country_name;
  //     return returnCountryIcon(ls_cntry) || '-------';
  //   } else {
  //     return '-------';
  //   }
  // }

    return (
        <>
        <div className="card height-equal">
            <div className="card-header card-header-border">
              <Row gutter={[0, 20]}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <h5>{ls_headerText}</h5>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className="pull-right">
                  {lb_showDocTypeTag ? <Space size="middle">
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                      {
                        la_documentTypes?.map((rowValue) =>
                          <div key={rowValue?.id} style={{ padding: `9px ${rowValue?.documents_count?.toString()?.length * 6}px 5px 5px`, display: 'flex', justifyContent: 'flex-start' }}>

                            <Badge count={rowValue?.category_name ? parseInt(rowValue.documents_count) : 0} color={'#2fc3e5'} overflowCount={100000000000000} >
                              <Tag onClick={() => { handleTagPress(rowValue?.id); }}
                                style={{ cursor: "pointer" }}
                                color={clickedTagIds.includes(rowValue.id) ? 'green' : 'geekblue'}
                              >{rowValue?.category_name}</Tag>
                            </Badge>
                          </div>)
                      }
                    </div>
                  </Space> : <></>}
                  {lb_showSearch ?
                    <div>
                      <Row justify={'end'}>
                        <Col xs={24} sm={24} md={8} lg={8}>
                          <Search allowClear onChange={(e) => handleSearch(e.target.value)} placeholder="Search here..." />
                        </Col>
                      </Row>
                    </div>
                    : <></>}
                </Col>
              </Row>
            </div>
            <Row>
                <Col span={24} style={{ padding: 15 }}>
                    <div className="custom-doc-list-parent">
                    <List
                        itemLayout="vertical"
                        loading={lb_loading}
                        pagination={{
                        onChange: handlePaginationChange,
                        pageSize: 10,
                        showSizeChanger: false,
                        position: "bottom",
                        align: "end",
                        total: totalCount,
                        }}
                        dataSource={la_documentList}
                        renderItem={(item, inx) =>
                        (

                        <List.Item
                            key={item.icon}
                            style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            actions={getActionForListing(item, inx)}
                            extra={<></>}
                        >


                            <List.Item.Meta
                            // avatar={
                            // item?.project?.country[0]?.country_name ? returnCountryIcon(item?.project?.country[0]?.country_name) : ''
                            // }
                            title={<h6 className="mb-0 f-w-700"><i style={{ color: "#4a1b97" }} className="icofont icofont-law-document"></i> <Link to={'/other-documents/document-details'} state={{ documentId: (item?.document_id || item?.id) }} style={{color: 'var(--bs-heading-color)'}}>{item.title || item?.otherdocuments[0]?.title}</Link> 
                                &nbsp;<Popover ref={popoverRef} placement="left" content={getDocsDetailsContent((item?.otherdocuments && item?.otherdocuments[0]) || item)} trigger="click" overlayClassName='col-xl-4 xl-100'>
                                <InfoCircleTwoTone style={{cursor:"pointer"}}/>
                                </Popover>
                        </h6>}
                            // description={
                            //     <>
                            //     <p className='p-text'><i style={{ color: "#7534e08c" }}
                            //         className="icofont icofont-ui-settings"></i>
                            //         {item?.project?.project_name || (item?.otherdocuments && item?.otherdocuments[0]?.project?.project_name)}</p>
                            //     </>
                            // }
                            />
                        </List.Item>
                        )}
                    />

                    </div>
                </Col>
            </Row>
        </div>
        <ShareModal
          visible={lb_shareModal}
          onCancel={() => setShareModal(false)}
          ls_documentId={ls_documentId}
          // onOk={handleReviewButtonPress}
          // loading={lb_buttonLoading}
        />
        <DeleteModal
          visible={modalVisible}
          title="Alert"
          content="Are you absolutely certain you wish to delete this document?"
          onCancel={() => setModalVisible(false)}
          onOk={handleDeleteButtonPress}
          loading={lb_buttonLoading}
        />
        <TransferDocument
          visible={lb_transferModal}
          onCancel={() => setTransferModal(false)}
          ls_documentId={ls_documentId}
          getSearchData={getSearchData}
        />
        </>
    )
}

export default DocumentListing