import { Button, Cascader, Col, Divider, Form, Input, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import './style.css'
import { SearchOutlined } from '@ant-design/icons';
import Typography from 'antd/lib/typography/Typography';
import { getRequestApiByToken } from '../../../api';
import { debounce } from 'lodash';
import { DatePicker, Spin } from 'antd/lib';
import { DateFormat } from '../../../constant/globalConfig';
const { Search } = Input;

const SearchForm = ({ advanceForm, handleSearchData = () => {}, lb_loading}) => {
  const [la_userDetails, setUserDetails] = useState([]);
  const [la_projectList, setProjectList] = useState([]);
  const [lb_projectLoading, setProjectLoading] = useState(false);
  const [la_sectorData, setSectorData] = useState([])
  const [la_groupData, setGroupData] = useState([])

  const getAllUserList = async () => {
    const res = await getRequestApiByToken('users')
    setUserDetails(res?.Users || [])
  };

  const getProjectList = async (filterText) => {
    setProjectLoading(true)
    if (filterText) {
      const res = await getRequestApiByToken(`project?search=${filterText}&pagination=false`);
      setProjectList(res?.Projects?.data);
    }
    setProjectLoading(false);
  }

  const getGroupData = async () => {
    const res = await getRequestApiByToken('group_master')
    setGroupData(res?.group_master || [])
  }

  const getSectorData = async () => {
    const res = await getRequestApiByToken('sector_master')
    setSectorData(res?.sector_master || [])
  }

  useEffect(() => {
    getAllUserList();
    // getProjectList();
    getSectorData()
    getGroupData();
  }, [])

  return (
    <>
      <Card>
        <CardBody>
          <Form
            className="theme-form"
            labelCol={{ span: 24 }}
            onFinish={handleSearchData}
            form={advanceForm}
          >
            <Row gutter={[6]}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item name='search' >
                  <Input allowClear placeholder="Enter Project name / Mandate no / Document name" suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)', }} />} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={6} md={6} lg={6}>
                <Form.Item label="Document Type" name="document_type">
                  <Select placeholder="Select Document Type" allowClear>
                    <Select.Option value="" key='all'>All</Select.Option>
                    <Select.Option value="project_document" key='project_document'>Project document</Select.Option>
                    <Select.Option value="other_document" key='other_document'>Other document</Select.Option>
                    <Select.Option value="pds" key='pds'>PDS</Select.Option>
                    <Select.Option value="tor" key='tor'>TOR</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6} md={6} lg={6}>
                <Form.Item label="User Actions" name="type">
                  <Select placeholder="Select Type" allowClear>
                    <Select.Option value="" key='all'>All</Select.Option>
                    <Select.Option value="share" key='1'>Shared</Select.Option>
                    <Select.Option value="download" key='2'>Downloaded</Select.Option>
                    <Select.Option value="view" key='3'>Viewed</Select.Option>
                    <Select.Option value="approve" key='4'>Approved</Select.Option>
                    <Select.Option value="upload" key='5'>Uploaded</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6} md={6} lg={6}>
                <Form.Item
                  label="From Date"
                  name="fromdate"
                  style={{ width: '100%' }}
                >
                  <DatePicker format={DateFormat} style={{ width: '100%' }} needConfirm={false} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6} md={6} lg={6}>
                <Form.Item
                  label="To Date"
                  name="todate"
                  style={{ width: '100%' }}
                  size={12}
                >
                  <DatePicker format={DateFormat} style={{ width: '100%' }} needConfirm={false} />
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={6} md={6} lg={6}>
                <Form.Item
                  label="User"
                  name="user_id"
                  // rules={[{ required: true, message: "Please input User!" }]}
                >
                  <Select placeholder="Select User"
                    allowClear showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {la_userDetails?.map(rowValue => (
                      <Select.Option key={rowValue.id} value={rowValue.id} subcategory={rowValue?.children || []}>
                        {`${rowValue.name} (${rowValue.email})`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item label="Select Project" name="project_id">
                  <Select
                    placeholder="Select Project Name"
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={false}
                    onSearch={debounce((value) => { getProjectList(value) }, 500)}
                    notFoundContent={lb_projectLoading ? <Spin size="small" /> : null}
                    allowClear
                    style={{ width: '100%' }}
                  >
                    {
                      la_projectList.map((rowVal, indx) => <Select.Option key={rowVal.id} value={rowVal.id}>{rowVal.project_name}</Select.Option>)
                    }
                  </Select>
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={6} md={6} lg={6}>
                <Form.Item
                  label="Sectors"
                  name="sector_id"
                >
                  <Select
                    placeholder="Select Sectors"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {la_sectorData.map(sector => (
                      <Select.Option key={sector.id} value={sector.id}>{sector.sector_name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={6} md={6} lg={6}>
                <Form.Item
                  label="Group"
                  name="group_id"
                >
                  <Select
                    placeholder="Select Group"
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {la_groupData.map(rowValue => (
                      <Select.Option key={rowValue.id} value={rowValue.id}>{rowValue.group_name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              
              
              
              
              <Col xs={24} sm={6} md={6} lg={6}>
                <div className="serchdoc-btn" style={{float:"right", minWidth:285}}>
                  <Button
                    loading={lb_loading}
                    icon={<i className="fa fa-search" style={{ color: 'white' }} />}
                    className="btn btn-primary button-style"
                    type="button"
                    htmlType='submit'
                    style={{ marginInlineStart: 3 }}
                  >Search</Button>
                </div>
              </Col>
              
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  )
}

export default SearchForm